<template>
  <v-card class="p-rel">
    <v-btn x-small fab dark color="primary" class="close-modal" @click="$emit('close')">
      <v-icon small color="white">$close</v-icon>
    </v-btn>
    <v-row class="ma-0 white">
      <modals-sidebar title="Ajouter un professionnel" />
      <v-col cols="11" class="pt-2">
        <v-form ref="form" lazy-validation class="py-3">
          <form-category title="Equipe soignante" subtitle="Ajouter un professionnel à mon équipe soignante" />

          <v-select label="Rechercher un professionnel" dense
                    hide-details="auto"
                    class="mb-5"
          />
          <v-textarea label="Commentaire" dense hide-details="auto"
                      class="mb-5"
          />
          <v-spacer class="mt-2" />
          <v-row class="text-center justify-center">
            <v-btn color="primary" x-small type="submit" class="mt-4">
              Enregistrer
            </v-btn>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import ModalsSidebar from '@/modules/core/modals/ModalsSidebar'
  import FormCategory from '@/modules/core/layout/FormCategory'

  export default {
    name: 'PatientMedicalTeamForm',
    components: {FormCategory, ModalsSidebar}
  }
</script>
